import data from './data'
import React from 'react';


function Tweets({ last, user }) {

    const [disabl, setdisabl] = React.useState(false);


    return (
        <ul>
            {(user ? data.filter((tweet) => {
                return tweet.username === user
            }) : data).map((tweet) => {
                
               
            
                
            })}
        </ul>
    )
}

export default Tweets;