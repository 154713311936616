import { Redirect, Route, useLocation } from "react-router";
import useToken from "../Hooks/Authentication";

function Public(props){
    const [token] = useToken();

    const {pathname} = useLocation();

    if(token && pathname === '/login'){
        return <Redirect to="/"/>
    }
    return <Route {...props}/>

}

export default Public;