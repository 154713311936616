import {Switch} from 'react-router-dom'
import './App.css';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Profile from './Pages/Profile/Profile';
import Login from './Pages/Login/Login'
import Public from './Routes/Public';
import Private from './Routes/Private';

function App() {

  return (
   <>

    <Switch>
      <Private path="/" component={Home} exact/>
      <Private path="/about" component={About} exact/>
        <Private path="/profile/:username" component={Profile} exact />
      <Private path="/profile" component={Profile} exact/>
      <Public path="/login" component={Login} exact/>
    </Switch>
    
   </>
  );
}

export default App;
