import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import {Provider as AuthProvider} from './Context/AuthToken'

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
    {/* <ThemeProvider> */}
  <AuthProvider>
    <App />
  </AuthProvider>
        {/* </ThemeProvider> */}
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
  );
  