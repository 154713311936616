import './profile.scss';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/footer/footer";
import { useParams, useHistory, Link } from 'react-router-dom'
import Tweets from '../../Components/Tweets/Tweets'



// IMAGES //
import avatar from '../../img/avatar.jpg';
import shashlik from '../../img/shashlik.jpg';
import star from '../../img/star.svg';
import comment from '../../img/comment.svg';
import retweet from '../../img/retweet.svg';
import like from '../../img/like.svg';
import likes from '../../img/likes.svg';
import share from '../../img/share.svg';
import statistics from '../../img/statistics.svg';



function Profile() {



    const history = useHistory()

    const { username } = useParams()

  

    return (<>
        <section className="profile">
            <div className="container">
                <Header />
                <section className="center">
                    <div className="title__wrapper">
                      <div className="name__wrapper">
                            <button className="backarrow" onClick={() => history.push('/')}>&larr;</button>
                            <span>
                                <h3 className="Home__title">Bobur</h3>
                                <p className="">1,070 Tweets</p>
                            </span>
                      </div>
                        <button><img src={star} alt="star" onClick={() =>
                            document.body.classList.toggle('dark')}/></button>
                    </div>
                    <ul className="contents">

                        <li className="mail">
      <div className="images">
          <img src={avatar} alt="" className="avatar" />
      </div>
                            <button className="edit">Edit profile</button>
        <div className="text__wrapp">
                                <h4 className="name">Bobur</h4>
                                <span className="username">@bobur_mavlonov</span>

                                <h5 className="names">UX&UI designer at</h5><span className="oficusername"> @abutechuz</span>

                                <div className="about__links">
                                    <p className="gio"><i class='bx bx-location-plus'></i>Mashag’daman</p>
                                    <a href="http://t.me/fullstack_5" className="tme"><i class='bx bx-link' ></i>t.me/boburjon_mavlonov</a>
                                    <p className="gio">Born November 24, 2000</p>
                                    <p className="gio"><i class='bx bx-calendar' ></i>Joined May 2020</p>
                                </div>
                                <div className="follows">
                                    <span>
                                        <h3 className="followcount">67</h3>
                                        <p className="follow">Following</p>
                                    </span>
                                    <span>
                                        <h3 className="followcount">47</h3>
                                        <p className="follow">Followers</p>
                                    </span>
                                </div>
                              <div className="pages_link">
                                    <Link className="link" activeclassName="active-link" to="/" exact>Tweets</Link>
                                    <Link className="link" activeclassName="active-link" to="/" exact>Tweets & replies</Link>
                                    <Link className="link" activeclassName="active-link" to="/" exact>Media</Link>
                                    <Link className="link" activeclassName="active-link" to="/" exact>Likes</Link>
                                    
                              </div>
        </div>
    </li>
                        <li className="mail two">
                            <div className="user__name__wrapper">
                                <img src={avatar} alt='img' className="avatar" />

                                <div className='text__wrapper'>
                                    <h4 className="name">Bobur</h4><span className="username">@bobur_mavlonov · <time>Apr 1</time></span>
                                    <button className="btns">···</button>
                                    <p className="one__text">4-kursni tugatgunimcha kamida bitta biznesim bo'lishini, uylanish uchun moddiy jihatdan to'la-to'kis tayyor bo'lishni, sog'lik va jismoniy holatni normallashtirishni reja qildim</p>
                                </div>
                            </div>
                            <div className="icon__wrapper">
                                <p><img src={comment} alt="img" />10</p>
                                <p><img src={retweet} alt="img" />1</p>
                                <label className="labes">
                                    <input className="chexbox" type="checkbox" />
                                    <img className="like" src={like} alt="img" />
                                    <img className="likes" src={likes} alt="img" />
                                </label>
                                <p><img src={share} alt="img" /></p>
                                <p><img src={statistics} alt="img" /></p>
                            </div>
                        </li>
                        <li className="mail two">
                            <div className="user__name__wrapper">
                                <img src={avatar} alt="img" className="avatar" />
                                <div className='text__wrapper'>
                                    <h4 className="name">Bobur</h4><span className="username">@bobur_mavlonov · <time>Apr 1</time></span>
                                    <button className="btns">···</button>
                                    <p className="two__text">Bizda shunaqa bir illat bor: gap bo'lsa bo'ldi, nima deyayotganimizga qarab ham o'tirmaymiz.

                                        Bitta biznes trener nito gapirib qo'ysa, hamma biznes trenerlar yomonga chiqadi slishkom aqlli odamlar nazdida.

                                        Gap faqat biznes trenerlar haqida emas.</p>
                                </div>
                            </div>
                            <div className="icon__wrapper">
                                <p><img src={comment} alt="img" /></p>
                                <p><img src={retweet} alt="img" />5</p>
                                <label className="labes">
                                    <input className="chexbox" type="checkbox" />
                                    <img className="like" src={like} alt="img" />
                                    <img className="likes" src={likes} alt="img" />
                                </label>
                                <p><img src={share} alt="img" /></p>
                                <p><img src={statistics} alt="img" /></p>
                            </div>
                        </li>
                        <li className="mail two thre">
                            <div className="user__name__wrapper">
                                <img src={avatar} alt="rasm" className="avatar" />
                                <div className='text__wrapper'>
                                    <h4 className="name">Bobur</h4><span className="username">@bobur_mavlonov · <time>Apr 1</time></span>
                                    <button className='btns'> ···</button>
                                    <p className="thre__text">A bo'pti maskamasman</p>
                                </div>

                                <img src={shashlik} alt="shashlik" className="shashlik" />
                            </div>
                
                                <div className="icon__wrapper three">
                                    <p><img src={comment} alt="img" />10</p>
                                    <p><img src={retweet} alt="img" />1</p>
                                    <label className="labes">
                                        <input className="chexbox" type="checkbox" />
                                        <img className="like" src={like} alt="img" />
                                        <img className="likes" src={likes} alt="img" />
                                    </label>
                                    <p><img src={share} alt="img" /></p>
                                    <p><img src={statistics} alt="img" /></p>
                                </div>

                        </li>
                    </ul>
                </section>

                <Tweets user={username} />
            
                <Footer />
            </div>
        </section>
    </>)
}



export default Profile;