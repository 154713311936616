import Header from "../../Components/Header/Header";


function About(){
    return(<>
        <Header/>

        <h1>About</h1>
    </>)
}

export default About;