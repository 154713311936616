import { useState } from "react";
// import Header from "../../Components/Header/Header";
import useToken from "../../Hooks/Authentication";

import './login.css'
import { Link } from 'react-router-dom';

import logo from '../../img/logo.svg'



function Login() {

    const [, setToken] = useToken()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    function handleLogin() {
        fetch('https://reqres.in/api/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        }).then((res) => res.json())
            .then((data) => setToken(data.token))
    }



    return (<>

        <div className="wrapper">
            <div className="form">
                <img src={logo} alt="logo" />
                <h1 className="login__title">Log in to Twitter</h1>

                <input placeholder="email" name="email" className="login__input" type="email" onChange={(e) => setEmail(e.target.value)}/>
                <input placeholder="password" className="login__input" type="password" onChange={(e) => setPassword(e.target.value)} />

                <button className="loginbtn" onClick={handleLogin}>Log In</button>


                <Link to="/" className="sigin" onClick={(e) => alert(`Email: eve.holt@reqres.in                        
Password: pistol`)}>Forgot password?</Link>
                <Link to="/" className="sigin two">Sign up to Twitter</Link>
            </div>
        </div>


    </>)
}

export default Login;



