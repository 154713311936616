import Header from "../../Components/Header/Header";
import Footer from "../../Components/footer/footer";
import './Home.scss'
import React from 'react';
import {Link} from 'react-router-dom'
import Tweets from '../../Components/Tweets/Tweets'
import { useEffect, useState } from 'react';



// import { useContext, useEffect } from "react";
// import { Context } from '../../Context/theme';



// IMAGES //
import avatar from '../../img/avatar.jpg';
import shashlik from '../../img/shashlik.jpg';
import star from '../../img/star.svg';
import image from '../../img/image.svg';
import gif from '../../img/gif.svg';
import stats from '../../img/stats.svg';
import smile from '../../img/smile.svg';
import comment from '../../img/comment.svg';
import retweet from '../../img/retweet.svg';
import like from '../../img/like.svg';
import likes from '../../img/likes.svg';
import share from '../../img/share.svg';
import statistics from '../../img/statistics.svg';
import lists from '../../img/lists.svg'





function Home() {

    const [users, setUsers] = useState([])


    useEffect(() => {
        fetch('https://reqres.in/api/users?page=2')
            .then(res => res.json())
            .then(data => setUsers(data.data));
    }, []);

   

    return (<>

 

        <section className={`home`}>
            <div className="container">

        <Header />



             
                <section className="center">
                    <div className="title__wrapper">
                        <h3 className="Home__title">Home</h3>
                        <button><img src={star} alt="star" onClick={() =>
                            document.body.classList.toggle('dark')} /></button>
                    </div>
                    <ul className="contents">
                       
                        <li className="mail">
                            <div className="avatar__wrapper">
                                <Tweets last={true} />
                                <img src={avatar} alt="avatar" className="avatar" />
                                <input type="text" placeholder="What’s happening"  />
                            </div>
                            <div className="icons__wrapper">
                                <span>
                                    <label>
                                        <input className="foto" type="file" accept="image/png, image/jpeg" />
                                        <img src={image} alt="icon" />
                                    </label>
                                    <label>
                                        <input className="foto" type="file" />
                                        <img src={gif} alt="icon" />
                                    </label>
                                    <label>
                                        <input className="foto" type="file" />
                                        <img src={stats} alt="icon" />
                                    </label>
                                    <label>
                                        <input className="foto" type="file" />
                                        <img src={smile} alt="icon" />
                                    </label>
                                    <label>
                                        <input className="foto" type="file" />
                                        <img src={lists} alt="icon" />
                                    </label>

                                </span>
                                <button>Tweet</button>
                            </div>
                        </li>
                        <li className="mail two">
                            <div className="user__name__wrapper">
                                <img src={avatar} alt='img' className="avatar" />

                                <div className='text__wrapper'>
                                    <h4 className="name">Jack</h4><span className="username">@inner · <time>25m</time></span>
                                    <button className="btns">···</button>
                                    <p className="one__text">Twitterdagi ayol-erkak qarama-qarshiliginglardan o'zinglar zerikmadinglarmi?</p>
                                </div>
                            </div>
                            <div className="icon__wrapper">
                                <p><img src={comment} alt="img" />10</p>
                                <p><img src={retweet} alt="img" />1</p>
                                <label className="labes">
                                    <input className="chexbox" type="checkbox" />
                                    <img className="like" src={like} alt="img" />
                                    <img className="likes" src={likes} alt="img" />
                                </label>
                                <p><img src={share} alt="img" /></p>
                                <p><img src={statistics} alt="img" /></p>
                            </div>
                        </li>
                        <li className="mail two">
                            <div className="user__name__wrapper">
                                <img src={avatar} alt="img" className="avatar" />
                                <div className='text__wrapper'>
                                    <h4 className="name">Mickel</h4><span className="username"> @RajLahoti · <time>22m</time></span>
                                    <button className="btns">···</button>
                                    <p className="two__text">YPIP dasturining bu yilgi sezoni ham o’z nihoyasiga yetmoqda. Mentorlik davomida talaba va yangi bitiruvchilarni o’sayotganini ko’rib hursand bo’ladi odam.</p>
                                </div>
                            </div>
                            <div className="icon__wrapper">
                                <p><img src={comment} alt="img" /></p>
                                <p><img src={retweet} alt="img" />5</p>
                                <label className="labes">
                                    <input className="chexbox" type="checkbox" />
                                    <img className="like" src={like} alt="img" />
                                    <img className="likes" src={likes} alt="img" />
                                </label>
                                <p><img src={share} alt="img" /></p>
                                <p><img src={statistics} alt="img" /></p>
                            </div>
                        </li>
                        <li className="mail two thre">
                            <div className="user__name__wrapper">
                                <img src={avatar} alt="rasm" className="avatar" />
                                <div className='text__wrapper'>
                                    <h4 className="name">CreativePhoto</h4><span className="username"> @cloutexhibition · <time>1h</time></span>
                                    <button className='btns'> ···</button>
                                    <p className="thre__text">Обетда..... Кечиринглар</p>
                                </div>

                                <img src={shashlik} alt="shashlik" className="shashlik" />
                            </div>
                            <div className="icon__wrapper">
                                <p><img src={comment} alt="img" />10</p>
                                <p><img src={retweet} alt="img" />1</p>
                                <label className="labes">
                                    <input className="chexbox" type="checkbox" />
                                    <img className="like" src={like} alt="img" />
                                    <img className="likes" src={likes} alt="img" />
                                </label>
                                <p><img src={share} alt="img" /></p>
                                <p><img src={statistics} alt="img" /></p>
                            </div>
                        </li>
                    </ul>
                </section>

<Tweets/>
               
                <Footer />
            </div>
        </section>
    </>)
}


export default Home;