import './Header.scss'
import {NavLink} from 'react-router-dom';
import useToken from '../../Hooks/Authentication';


// IMAGES //

import logo from '../../img/logo.svg';
import home from '../../img/home.svg';
import explore from '../../img/explore.svg';
import notification from '../../img/notification.svg';
import messages from '../../img/messages.svg';
import bookmarks from '../../img/bookmarks.svg';
import lists from '../../img/lists.svg';
import profil from '../../img/profil.svg';
import more from '../../img/more.svg';


function handleToken() {
    window.location.reload()
    window.localStorage.removeItem('token')
}

function Header(){

    const [token] = useToken()



    return(<>
     
       {/* navigatsiya */}

    <header>
                    <section className="navigatsiya">
                        <NavLink to="/"><img src={logo} alt="logo" className="logo" /></NavLink>
                        <ul className="menu">
                    <li className="menu__list">{token && <NavLink to="/" className="menu__link"><img src={home} alt="img" /> Home</NavLink>}</li>
                            {token && <li className="menu__list"><NavLink to="/about" className="menu__link"><img src={explore} alt="img" />Explore</NavLink></li>}
                    <li className="menu__list">{token && <NavLink to="/" className="menu__link"><img src={notification} alt="img" />Notifications</NavLink>}</li>
                    <li className="menu__list">{token && <NavLink to="/" className="menu__link"><img src={messages} alt="img" />Messages</NavLink>}</li>
                    <li className="menu__list">{token && <NavLink to="/" className="menu__link"><img src={bookmarks} alt="img" />Bookmarks</NavLink>}</li>
                    <li className="menu__list">{token && <NavLink to="/lists" className="menu__link"><img src={lists} alt="img" />Lists</NavLink>}</li>
                    <li className="menu__list">{token && <NavLink to="/profile" className="menu__link"><img src={profil} alt="img" />Profile</NavLink>}</li>
                            <li className="menu__list">
                                {!token && <NavLink to="/login" className="menu__link"><img src={more} alt="img" />More</NavLink>}</li>
                        </ul>
<button className="tweetbtn">Tweet</button>

                <button className="logoutbtn" onClick={handleToken}>Log out</button>
                    </section>
                     
        
    </header>
    
    </>)
}

export default Header;