import './footer.scss'
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'

// IMAGES //
import avatar from '../../img/avatar.jpg';
import settings from '../../img/settings.svg';
import search from '../../img/search.svg';





function Footer() {

    const [users, setUsers] = useState([])


    useEffect(() => {
        fetch('https://reqres.in/api/users?page=2')
            .then(res => res.json())
            .then(data => setUsers(data.data));
    }, []);

    return (<>


            <footer className="fallowers">
                <label className="search__wrapper">
                    <img src={search} alt="icon" className="search__icon" />
                    <input type="search" placeholder="Search Twitter" />
                </label>
                <div className="trends">
                    <div className="settings__wrapper">
                        <h2 className="settings__title">Trends for you</h2>
                        <button className="settings__btn"><img src={settings} alt="setings" /></button>
                    </div>

                    <div className="col">
                        <p className="subtext">Trending in Germany</p>
                        <h3 className="subtitle">Revolution</h3>
                        <p className="subtext">50.4K Tweets</p>
                    </div>
                    <div className="col">
                        <p className="subtext">Trending in Germany</p>
                        <h3 className="subtitle">Revolution</h3>
                        <p className="subtext">50.4K Tweets</p>
                    </div>
                    <div className="col">
                        <p className="subtext">Trending in Germany</p>
                        <h3 className="subtitle">Revolution</h3>
                        <p className="subtext">50.4K Tweets</p>
             </div>
                    <Link to="/" className="more__btn">Show more</Link>
                </div>
                <div className="likes">
                    <h2 className="like__title">You might like</h2>
                <ul>
                    {users.map((user) => {
                        return (         
                                <div key={user.id} className="likes__blok">
                                    <img src={user.avatar} alt="img" className="avatar" />
                                <Link className="link" to={`/`}>
                                    <span>
                                        <h3 className="like__title">{user.first_name}</h3>
                                        <p className="like__username">@{user.last_name}</p>
                                    </span>
                                </Link>

                                    <button className="fallow__btn">Follow</button>
                                </div>
                        )
                    })}
                </ul>
                   
                    <Link to="/" className="more__btn">Show more</Link>

                </div>
                <div className="links">
                <p className="link">Terms of Service</p>
                <p className="link">Privacy Policy</p>
                <p className="link">Cookie Policy</p>
                <p className="link">Imprint</p>
                <p className="link">Ads Info</p>
                <p className="link">More ···</p>
                <p className="link">© 2021 Twitter, Inc.</p>
                </div>
            </footer>


    </>)

    }

export default Footer;

